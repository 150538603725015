<template>
    <div>
        <MoreInfo></MoreInfo>
        <h3 class="text-center mb-3">{{ officeName }} <span class="font-weight-light">({{ cityName }})</span></h3>
        <v-card class="elevation-1 mx-7">
            <v-card-title>
            Recaudos Registrados
            <v-spacer></v-spacer>
            <v-btn
            :to="$route.params._id+'/create'"
            class="mx-auto"
                elevation="2"
                color="primary"
            >Nuevo Recaudo</v-btn>
            </v-card-title>
            <v-text-field
                class="mx-4"
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
            ></v-text-field>
            <v-data-table
                :headers="headers"
                :items="dataset"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-text':'Filas por Página',
                    'items-per-page-all-text': 'Todos'
                }"
                :headerProps="{
                    sortByText:'Ordenar por...'
                }"
                :no-data-text="'No hay datos disponibles'"
            >
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="editItem(item._id)"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="printItem(item._id)"
                            >
                                mdi-printer
                            </v-icon>
                        </template>
                        <span>Imprimir Tirilla</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="payItem(item._id)"
                            >
                                mdi-cash
                            </v-icon>
                        </template>
                        <span>Pagar Recaudo</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteItem(item._id)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
        <Report :runNow="runReport" style="display: none"></Report>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import { titleCase, prettyCurrency, prettyDate } from '@/modules/tools';


const store = new Storage();

import MoreInfo from '@/components/Layout/MoreInfo.vue'
import Report from "./CollectionReport.vue"
export default {
    name: 'CollectionMain',
    components: {
        MoreInfo,
        Report
    },
    data() {
        return {
            officeName: '',
            runReport: '',
            cityName: '',
            sortBy: 'name',
            sortDesc: false,
            dialog: false,
            dialogDelete: false,
            search: '',
            dataset: [],
            headers: [
                {
                    text: 'Código',
                    align: 'start',
                    value: 'code',
                },
                { text: 'Estado de Pago', value: 'status' },
                { text: 'Tipo de Pagador', value: 'collectionType' },
                { text: 'Nombre', value: 'name' },
                { text: 'Valor a Pagar', value: 'amount' },
                { text: 'Periodo', value: 'period' },
                { text: 'Fecha de Pago', value: 'registrationDate' },
                { text: 'Acciones', value: 'actions', sortable: false },
            ],
            months: [
                {name: 'Enero', value: 1},
                {name: 'Febrero', value: 2},
                {name: 'Marzo', value: 3},
                {name: 'Abril', value: 4},
                {name: 'Mayo', value: 5},
                {name: 'Junio', value: 6},
                {name: 'Julio', value: 7},
                {name: 'Agosto', value: 8},
                {name: 'Septiembre', value: 8},
                {name: 'Octubre', value: 10},
                {name: 'Noviembre', value: 11},
                {name: 'Diciembre', value: 12},
            ],
        }
    },
    async mounted() {
        this.officeName = await store.getData("office", this.$route.params._id);
        this.cityName = await store.getData("city", this.officeName.city);
        this.officeName = this.officeName.name;
        this.cityName = this.cityName.name;
        this.cityName = titleCase(this.cityName);

        const dataArray = await store.getData("collection");
        const affiliateData = await store.getData("affiliate");
        const contributorData = await store.getData("contributor");
        const branchData = await store.getData("branch");

        dataArray.forEach(async data => {
            contributorData.forEach(contributor => {
                if (contributor._id === data.contributor) {
                    data.collectionType = 'Aportante';
                    data.name = contributor.name;
                    data.office = contributor.office;
                }
            });

            affiliateData.forEach(affiliate => {
                if (affiliate._id === data.affiliate) {
                    branchData.forEach(branch => {
                        if (branch._id === affiliate.branch) {
                            data.contributor = branch.contributor;
                        }
                    });
                    contributorData.forEach(contributor => {
                        if (contributor._id === data.contributor) {
                            data.collectionType = 'Afiliado';
                            data.name = affiliate.name;
                            data.office = contributor.office;
                        }
                    });
                }
            });

            if (data.office === this.$route.params._id) {
                data.amount = await prettyCurrency(data.amount);
                data.registrationDate = await prettyDate(data.registrationDate);
                data.period = `${this.months[data.month-1].name} ${data.year}`;
                if (data.payrollCode) {
                    data.status = 'Pago';
                } else {
                    data.status = 'Pendiente';
                }
                this.dataset.push(data);
            }
        });
    },
    methods: {
        payItem(_id) {
            this.$router.push({ name: "Collection Pay", params: {
                _id: this.$route.params._id,
                object_id: _id
            }}); 
        },
        printItem(_id) {
            this.runReport = _id; // Esta es la version que descarga el PDF
            setTimeout(()=>{this.runReport = false;}, 100);
        },
        editItem(_id) {
            this.$router.push({ name: "Collection Edit", params: {
                _id: this.$route.params._id,
                object_id: _id
            }}); 
        },
        async deleteItem(_id) {
            Swal.fire({
                title: "¿Eliminar Recaudo?",
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#dc3545",
                cancelButtonColor: "#6c757d"
            }).then(result => {
                if (result.isConfirmed) {
                    Swal.fire({
                        icon: "warning",
                        title: "Eliminando Recaudo",
                        html:
                            "Por favor espere mientras se elimina el Recaudo</b> " +
                            '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        stopKeydownPropagation: true
                    });
                    store.deleteData("misalud", "collection", _id).then(() => {
                        Swal.fire({
                            icon: "success",
                            title: "Recaudo Eliminado",
                            text: "Todos los datos han sido sincronizados"
                        })
                        this.dataset.forEach((item, index) => {
                            if (item._id === _id) {
                                this.dataset.splice(index, 1);
                            }
                        });
                    });
                }
            });
        }
    },
}
</script>