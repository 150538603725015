<template>
    <div>
        <div class="mainReport" ref="mainReport">
            <div ref="dataSet">
                <p class="text-center ma-0">COMPROBANTE DE TRANSACCIÓN<br>MULTICANAL - PAGOS A TERCEROS</p>
                <p class="text-center ma-0"><strong>{{ company.name.toUpperCase() }}</strong></p>
                <p class="text-center ma-0">Dirección: {{ company.address }}</p>
                <p class="text-center ma-0">Tel: {{ company.mobile }}</p>
                <p class="text-center ma-0">{{ city }} - {{ state }}</p>
                <hr>
                <p v-if="collection.contributor" class="ma-0">Pagador: <strong>{{ collection.name }}</strong></p>
                <p v-if="collection.affiliate" class="ma-0">Usuario: <strong>{{ collection.name }}</strong></p>
                <p class="ma-0">{{ collection.IDType }}: <strong>{{ collection.ID }}</strong></p>
                <p class="ma-0">Vendedor: <strong>{{ collection.seller }}</strong></p>
                <hr>
                <div class="twoColumns">
                    <p class="ma-0">Fecha: <strong>{{ collection.registrationDate }}</strong></p>
                    <p class="text-right ma-0">Hora: <strong>{{ collection.registrationTime }}</strong></p>
                </div>
                <div class="twoColumns">
                    <p class="ma-0">Comprobante No°:</p>
                    <p class="text-right ma-0"><strong>{{ collection.code }}</strong></p>
                </div>
                <div class="twoColumns">
                    <p class="ma-0">Periodo:</p>
                    <p class="text-right ma-0"><strong>{{ collection.period }}</strong></p>
                </div>
                <div v-if="collection.affiliate">
                    <hr>
                    <p class="text-center ma-0">Transacciones Realizadas</p>
                    <p v-if="collection.AFP" class="ma-0">Aportes de Pensión:</p>
                    <p v-if="collection.AFP" class="ma-0"><strong>{{ collection.AFP }}</strong></p>
                    <p v-if="collection.EPS" class="ma-0">Aportes de Salud:</p>
                    <p v-if="collection.EPS" class="ma-0"><strong>{{ collection.EPS }}</strong></p>
                    <p v-if="collection.ARLRate" class="ma-0">Aportes de Riesgos Laborales:</p>
                    <p v-if="collection.ARLRate" class="ma-0"><strong>{{ collection.ARL }}</strong></p>
                    <p v-if="collection.CCF" class="ma-0">Aportes de Caja de Compensación:</p>
                    <p v-if="collection.CCF" class="ma-0"><strong>{{ collection.CCF }}</strong></p>
                </div>
                <hr>
                <div class="twoColumns">
                    <p class="my-1"><strong>Total de Aportes:</strong></p>
                    <p class="text-right my-1"><strong>{{ collection.amount }}</strong></p>
                </div>
                <hr>
                <p class="text-center ma-0">Recuerde que los pagos de seguridad social deben realizarse antes de la fecha máxima de pago establecida, evite ser suspendido del servicio por su entidad.</p>
                <div class="centerQR">
                    <div class="QRContainer">
                        <canvas ref="canvas"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { jsPDF } from "jspdf";
import { prettyDate, prettyTime, prettyCurrency, prettyID, titleCase } from "@/modules/tools";
import Swal from "sweetalert2";
import QRCode from "qrcode";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
    name: 'ReportTemplate',
    data() {
        return {
            company: {},
            city: '',
            state: '',
            collection: {},
            months: [
                {name: 'Enero', value: 1},
                {name: 'Febrero', value: 2},
                {name: 'Marzo', value: 3},
                {name: 'Abril', value: 4},
                {name: 'Mayo', value: 5},
                {name: 'Junio', value: 6},
                {name: 'Julio', value: 7},
                {name: 'Agosto', value: 8},
                {name: 'Septiembre', value: 8},
                {name: 'Octubre', value: 10},
                {name: 'Noviembre', value: 11},
                {name: 'Diciembre', value: 12},
            ],
        }
    },
    props: ["runNow"],
    watch: {
        runNow: function(value) {
            if (value) {
                this.run();
            }
        }
    },
    methods: {
        async run() {
            Swal.fire({
                icon: "warning",
                title: "Generando Reporte",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                stopKeydownPropagation: true
            });

            const collection = await store.getData("collection", this.runNow);
            const contributorData = await store.getData("contributor");
            const branchData = await store.getData("branch");
            const affiliateData = await store.getData("affiliate");
            const ARLData = await store.getData("ARL");
            const AFPData = await store.getData("AFP");
            const CCFData = await store.getData("CCF");
            const EPSData = await store.getData("EPS");

            collection.registrationTime = await prettyTime(collection.registrationDate);
            collection.registrationDate = await prettyDate(collection.registrationDate);
            collection.period = `${this.months[collection.month-1].name} ${collection.year}`;
            collection.amount = await prettyCurrency(collection.amount);
            collection.seller = localStorage.getItem('user.name'); // Cambiar a responsable de oficina cuando este usuarios

            if (collection.contributor) {
                contributorData.forEach(async contributor => {
                    if (contributor._id == collection.contributor) {
                        collection.IDType = 'NIT';
                        collection.ID = await prettyID(contributor.ID);
                        collection.name = contributor.name;
                    }
                });
            } else if (collection.affiliate) {
                affiliateData.forEach(async affiliate => {
                    if (affiliate._id == collection.affiliate) {
                        collection.IDType = affiliate.IDType;
                        collection.ID = await prettyID(affiliate.ID);
                        collection.name = affiliate.name;
 
                        collection.ARLRate = affiliate.ARLRate;
                        branchData.forEach(branch => {
                            if (branch._id == affiliate.branch) {
                                contributorData.forEach(contributor => {
                                    if (contributor._id == branch.contributor) {
                                       ARLData.forEach(ARL => {
                                            if (ARL._id == contributor.ARL) {
                                                collection.ARL = ARL.name;
                                            }
                                        }); 
                                    }
                                });
                            }
                        });
                        AFPData.forEach(AFP => {
                            if (AFP._id == affiliate.AFP) {
                                collection.AFP = AFP.name;
                            }
                        });
                        CCFData.forEach(CCF => {
                            if (CCF._id == affiliate.CCF) {
                                collection.CCF = CCF.name;
                            }
                        });
                        EPSData.forEach(EPS => {
                            if (EPS._id == affiliate.EPS) {
                                collection.EPS = EPS.name;
                            }
                        });
                    }
                });
            }

            this.collection = collection;

            QRCode.toCanvas(
                this.$refs.canvas,
                'https://assoft.co',
                { errorCorrectionLevel: "H" },
                function(error) {
                    if (error) console.error(error);
                }
            );

            const doc = new jsPDF("p", "pt", [730, 330]);
            doc.html(this.$refs.mainReport, {
                html2canvas: {
                scale: 0.94,
                useCORS: true
                },
                callback: function(doc) {
                doc.save(`Tirilla ${collection.name} ${collection.period}.pdf`);
                Swal.fire({
                    icon: "success",
                    title: "Tirilla Generada",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: toast => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                    }
                });
                }
            });
        }
    },
    async mounted() {
        this.company = await store.getData("company");
        this.company = this.company[0];
        const office = await store.getData("office", this.$route.params._id);
        const cityData = await store.getData("city");
        const stateData = await store.getData("state");

        cityData.forEach(city => {
            if (city._id == office.city) {
                office.state = city.state;
                this.city = titleCase(city.name);
            }
        });

        stateData.forEach(state => {
            if (state._id == office.state) {
                this.state = titleCase(state.name);
            }
        });
    },
}
</script>

<style lang="css" scoped>
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
.mainReport {
  background-color: white;
  color: rgb(32, 32, 32);
  padding: 10px;
  width: 350px;
  font-family: Arial, Helvetica, sans-serif;
}
.mainReport > div {
    border: 1px solid rgb(32, 32, 32);
    padding: 10px;
}
.twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.centerQR {
    display: flex;
    justify-content: center;
}
.QRContainer {
    width: 150px;
    height: 150px;
}
hr {
  border-top: 1px dashed rgb(32, 32, 32) !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
</style>